import ApiService from "@/core/services/api.service";

// mutation types
export const GET_ALL_TRANSACTIONS = "GET_ALL_TRANSACTIONS";
export const SetTransactions = "SetTransactions";

export const DELETED_TRANSACTIONS = "DELETED_TRANSACTIONS";

export const VIEW_TRANSACTION = "VIEW_TRANSACTION";

export const transactionErrors = "transactionErrors";

const state = {
  allTransactions: [],
  errors: [],
};

const getters = {
  getTransactions: (state) => state.allTransactions,
};

const mutations = {
  SetTransactions: (state, payload) => (state.allTransactions = payload),
  transactionErrors: (state, payload) => (state.errors = payload),
};

const actions = {
  GET_ALL_TRANSACTIONS(
    { commit },
    { pageindex, pagesize, accountKey, transactionStatus }
  ) {
    ApiService.get(
      `Transactions/GetAllTransactionsBySavingAccountKey?SavingAccountKey=${accountKey}&PageIndex=${pageindex}&PageSize=${pagesize}&Status=${transactionStatus}`
    )
      .then((res) => {
        commit(SetTransactions, res.data);
      })
      .catch((error) => {
        commit(transactionErrors, error.message);
      });
  },

  DELETED_TRANSACTIONS({ commit }, { accountKey }) {
    return new Promise((resolve, reject) => {
      ApiService.delete(
        `Transactions/adjusttransaction/${accountKey}`
      )
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          commit(transactionErrors, error.message);
          reject(error);
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};

import {
  GET_ALL_MENU,
  GET_ALL_VIEW,
  GET_CUSTOM_EXPORT_DATA,
  GET_GROUPED_MENU,
  GET_LINKING_OPERATOR,
  GET_MENU,
  GET_MENU_VIEW_MODE,
  GET_MODEL_PROPERTY,
  GET_SINGLE_MENU,
  GET_SINGLE_VIEW,
  GET_SORTING_VALUES,
  GET_VIEW_DATA,
  GET_VIEW_DATA_NO_PAG,
  GET_VIEW_NAME,
  LOADING_ALL_MENU,
  LOADING_ALL_VIEW,
  LOADING_MENU,
  LOADING_VIEW,
  SET_ALL_MENU,
  SET_ALL_VIEW,
  SET_CUSTOM_EXPORT_DATA,
  SET_GROUPED_MENU,
  SET_LINKING_OPERATOR,
  SET_MENU,
  SET_MENU_VIEW_MODE,
  SET_MODEL_PROPERTY,
  SET_SINGLE_MENU,
  SET_SINGLE_VIEW,
  SET_SORTING_VALUES,
  SET_VIEW_DATA,
  SET_VIEW_DATA_NO_PAG,
  SET_VIEW_NAME,
} from "./types";

import GeneralApiService from "@/core/services/general.service";

const state = {
  menu: [],
  groupedMenu: [],
  allMenu: [],
  viewName: "",
  singleMenu: null,
  allView: [],
  viewData: null,
  viewDataNoPag: null,
  singleView: null,
  modelProperty: [],
  linkingOperator: [],
  sortingValue: [],
  menuViewMode: [],
  saving: false,
  loading: false,
  loadingAll: false,
  loadingAllView: false,
  customViewExport: [],
};

const mutations = {
  [SET_GROUPED_MENU](state, payload) {
    state.groupedMenu = payload;
  },
  [SET_CUSTOM_EXPORT_DATA](state, payload) {
    state.customViewExport = payload;
  },
  [SET_MENU](state, payload) {
    state.menu = payload;
  },
  [LOADING_MENU](state, payload) {
    state.loading = payload;
  },
  [LOADING_VIEW](state, payload) {
    state.loading = payload;
  },
  [SET_ALL_MENU](state, payload) {
    state.allMenu = payload;
  },
  [SET_ALL_VIEW](state, payload) {
    state.allView = payload;
  },
  [SET_VIEW_DATA](state, payload) {
    state.viewData = payload;
  },
  [SET_VIEW_DATA_NO_PAG](state, payload) {
    state.viewDataNoPag = payload;
  },
  [SET_VIEW_NAME](state, payload) {
    state.viewName = payload;
  },
  [SET_SINGLE_MENU](state, payload) {
    state.singleMenu = payload;
  },
  [SET_SINGLE_VIEW](state, payload) {
    state.singleView = payload;
  },
  [SET_MODEL_PROPERTY](state, payload) {
    state.modelProperty = payload;
  },
  [SET_LINKING_OPERATOR](state, payload) {
    state.linkingOperator = payload;
  },
  [SET_SORTING_VALUES](state, payload) {
    state.sortingValue = payload;
  },
  [SET_MENU_VIEW_MODE](state, payload) {
    state.menuViewMode = payload;
  },
  [LOADING_ALL_MENU](state, payload) {
    state.loadingAll = payload;
  },
  [LOADING_ALL_VIEW](state, payload) {
    state.loadingAllView = payload;
  },
};

const actions = {
  [GET_GROUPED_MENU]({ commit }) {
    commit(LOADING_MENU, true);
    GeneralApiService.get(`CustomMenu/groupedmenu`)
      .then((res) => {
        commit(LOADING_MENU, false);
        commit(SET_GROUPED_MENU, res.data);
      })
      .catch(() => {
        commit(LOADING_MENU, false);
        commit(SET_GROUPED_MENU, null);
      });
  },
  [GET_MENU]({ commit }) {
    commit(LOADING_MENU, true);
    GeneralApiService.get(`CustomMenu/customMenuTypes`)
      .then((res) => {
        commit(LOADING_MENU, false);
        commit(SET_MENU, res.data);
      })
      .catch(() => {
        commit(LOADING_MENU, false);
        commit(SET_MENU, null);
      });
  },
  [GET_ALL_MENU]({ commit }) {
    commit(LOADING_ALL_MENU, true);
    GeneralApiService.get(`CustomMenu/AllMenus`)
      .then((res) => {
        commit(LOADING_ALL_MENU, false);
        commit(SET_ALL_MENU, res.data);
      })
      .catch(() => {
        commit(LOADING_ALL_MENU, false);
        commit(SET_ALL_MENU, null);
      });
  },
  [GET_SINGLE_MENU]({ commit }, key) {
    commit(LOADING_MENU, true);
    GeneralApiService.get(`CustomMenu/CustomMenus/${key}`)
      .then((res) => {
        commit(LOADING_MENU, false);
        commit(SET_SINGLE_MENU, res.data);
      })
      .catch(() => {
        commit(LOADING_MENU, false);
        commit(SET_SINGLE_MENU, null);
      });
  },
  [GET_ALL_VIEW]({ commit }, { key, page, size }) {
    commit(LOADING_ALL_VIEW, true);
    GeneralApiService.get(
      `CustomView/getCustomViewsByParentMenu/${key}/${page}/${size}`
    )
      .then((res) => {
        commit(LOADING_ALL_VIEW, false);
        commit(SET_ALL_VIEW, res.data);
      })
      .catch(() => {
        commit(LOADING_ALL_VIEW, false);
        commit(SET_ALL_VIEW, null);
      });
  },
  [GET_VIEW_DATA]({ commit }, { id, page, size }) {
    commit(LOADING_VIEW, true);
    GeneralApiService.get(
      `CustomView/getCustomViewData/${id}/${page}/${size}`
    )
      .then((res) => {
        commit(LOADING_VIEW, false);
        commit(SET_VIEW_DATA, res.data);
      })
      .catch(() => {
        commit(LOADING_VIEW, false);
        commit(SET_VIEW_DATA, null);
      });
  },
  [GET_CUSTOM_EXPORT_DATA]({ commit }, { id, page, size }) {
    GeneralApiService.get(
      `CustomView/getCustomViewData/${id}/${page}/${size}`
    )
      .then((res) => {
        commit(SET_CUSTOM_EXPORT_DATA, res.data);
      })
      .catch(() => {
        commit(SET_CUSTOM_EXPORT_DATA, []);
      });
  },
  [GET_VIEW_DATA_NO_PAG]({ commit }, id) {
    commit(LOADING_VIEW, true);
    GeneralApiService.get(
      `CustomView/getCustomViewDataList/${id}`
    )
      .then((res) => {
        commit(LOADING_VIEW, false);
        commit(SET_VIEW_DATA_NO_PAG, res.data);
      })
      .catch(() => {
        commit(LOADING_VIEW, false);
        commit(SET_VIEW_DATA_NO_PAG, null);
      });
  },
  [GET_VIEW_NAME]({ commit }, { id }) {
    commit(LOADING_VIEW, true);
    GeneralApiService.get(`CustomView/GetCustomViewName/${id}`)
      .then((res) => {
        commit(LOADING_VIEW, false);
        commit(SET_VIEW_NAME, res.data);
      })
      .catch(() => {
        commit(LOADING_VIEW, false);
        commit(SET_VIEW_NAME, null);
      });
  },
  [GET_SINGLE_VIEW]({ commit }, key) {
    commit(LOADING_MENU, true);
    GeneralApiService.get(`CustomView/${key}`)
      .then((res) => {
        commit(LOADING_VIEW, false);
        commit(SET_SINGLE_VIEW, res.data);
      })
      .catch(() => {
        commit(LOADING_VIEW, false);
        commit(SET_SINGLE_VIEW, null);
      });
  },
  [GET_MODEL_PROPERTY]({ commit }, key) {
    commit(LOADING_MENU, true);
    GeneralApiService.get(`CustomView/getModelProperty/${key}`)
      .then((res) => {
        commit(LOADING_MENU, false);
        commit(SET_MODEL_PROPERTY, res.data);
      })
      .catch(() => {
        commit(LOADING_MENU, false);
        commit(SET_MODEL_PROPERTY, []);
      });
  },
  [GET_LINKING_OPERATOR]({ commit }) {
    commit(LOADING_MENU, true);
    GeneralApiService.get(`CustomView/getLinkingOperator`)
      .then((res) => {
        commit(LOADING_MENU, false);
        commit(SET_LINKING_OPERATOR, res.data);
      })
      .catch(() => {
        commit(LOADING_MENU, false);
        commit(SET_LINKING_OPERATOR, []);
      });
  },
  [GET_SORTING_VALUES]({ commit }) {
    commit(LOADING_MENU, true);
    GeneralApiService.get(`CustomView/getSortingValues`)
      .then((res) => {
        commit(LOADING_MENU, false);
        commit(SET_SORTING_VALUES, res.data);
      })
      .catch(() => {
        commit(LOADING_MENU, false);
        commit(SET_SORTING_VALUES, []);
      });
  },
  [GET_MENU_VIEW_MODE]({ commit }) {
    commit(LOADING_MENU, true);
    GeneralApiService.get(`CustomView/getMenuViewMode`)
      .then((res) => {
        commit(LOADING_MENU, false);
        commit(SET_MENU_VIEW_MODE, res.data);
      })
      .catch(() => {
        commit(LOADING_MENU, false);
        commit(SET_MENU_VIEW_MODE, []);
      });
  },
};

const getters = {
  loadMenus(state) {
    return state.menu;
  },
};
export default {
  state,
  actions,
  mutations,
  getters,
  namespace: true,
};

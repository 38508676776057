export const GET_LOAN_PRODUCTS = "GET_LOAN_PRODUCTS";
export const GET_LOAN_ACCOUNTS = "GET_LOAN_ACCOUNTS";
export const GET_LOAN_EXPORTS = "GET_LOAN_EXPORTS";
export const LOADING_LOAN_ACCOUNTS = "LOADING_LOAN_ACCOUNTS";
export const SET_LOAN_ACCOUNTS = "SET_LOAN_ACCOUNTS";
export const SET_LOAN_EXPORTS = "SET_LOAN_EXPORTS";
export const SET_LOAN_PRODUCTS = "SET_LOAN_PRODUCTS";
export const SAVING_LOAN_PRODUCTS = "SAVING_LOAN_PRODUCTS";
export const SAVE_LOAN_PRODUCTS = "SAVE_LOAN_PRODUCTS";
export const LOADING_LOAN_PRODUCTS = "LOADING_LOAN_PRODUCTS";
export const LOADING_ALL_LOAN_PRODUCTS = "LOADING_ALL_LOAN_PRODUCTS";
export const GET_ALL_LOAN_PRODUCTS = "GET_ALL_LOAN_PRODUCTS";
export const SET_LOAN_PRODUCTS_ALL = "SET_LOAN_PRODUCTS_ALL";

export const GET_LOAN_ACCOUNT_DETAILS = "GET_LOAN_ACCOUNT_DETAILS";
export const SET_LOAN_ACCOUNT_DETAILS = "SET_LOAN_ACCOUNT_DETAILS";
export const LOADING_LOAN_ACCOUNT_DETAILS = "LOADING_LOAN_ACCOUNT_DETAILS";

export const GET_MENU_TYPES = "GET_MENU_TYPES";
export const GET_MENU = "GET_MENU";
export const SET_MENU = "SET_MENU";
export const LOADING_MENU = "LOADING_MENU";

export const GET_ALL_MENU = "GET_ALL_MENU";
export const SET_ALL_MENU = "SET_ALL_MENU";
export const LOADING_ALL_MENU = "LOADING_ALL_MENU";

export const GET_SINGLE_MENU = "GET_SINGLE_MENU";
export const SET_SINGLE_MENU = "SET_SINGLE_MENU";

export const GET_MODEL_PROPERTY = "GET_MODEL_PROPERTY";
export const SET_MODEL_PROPERTY = "SET_MODEL_PROPERTY";

export const GET_LINKING_OPERATOR = "GET_LINKING_OPERATOR";
export const SET_LINKING_OPERATOR = "SET_LINKING_OPERATOR";

export const GET_SORTING_VALUES = "GET_SORTING_VALUES";
export const SET_SORTING_VALUES = "SET_SORTING_VALUES";

export const GET_MENU_VIEW_MODE = "GET_MENU_VIEW_MODE";
export const SET_MENU_VIEW_MODE = "SET_MENU_VIEW_MODE";
export const GET_GROUPED_MENU = "GET_GROUPED_MENU";
export const SET_GROUPED_MENU = "SET_GROUPED_MENU";

export const GET_SINGLE_VIEW = "GET_SINGLE_VIEW";
export const SET_SINGLE_VIEW = "SET_SINGLE_VIEW";
export const LOADING_VIEW = "LOADING_VIEW";

export const GET_ALL_VIEW = "GET_ALL_VIEW";
export const SET_ALL_VIEW = "SET_ALL_VIEW";
export const LOADING_ALL_VIEW = "LOADING_ALL_VIEW";
export const SET_VIEW_DATA = "SET_VIEW_DATA";
export const GET_VIEW_DATA = "GET_VIEW_DATA";
export const GET_CUSTOM_EXPORT_DATA = "GET_CUSTOM_EXPORT_DATA";
export const SET_CUSTOM_EXPORT_DATA = "SET_CUSTOM_EXPORT_DATA";
export const GET_VIEW_DATA_NO_PAG = "GET_VIEW_DATA_NO_PAG";
export const SET_VIEW_DATA_NO_PAG = "SET_VIEW_DATA_NO_PAG";
export const GET_VIEW_NAME = "GET_VIEW_NAME";
export const SET_VIEW_NAME = "SET_VIEW_NAME";

export const GET_ALL_ROLE = "GET_ALL_ROLE";
export const SET_ALL_ROLE = "SET_ALL_ROLE";
export const GET_ROLE = "GET_ROLE";
export const SET_ROLE = "SET_ROLE";
export const GET_ROLES = "GET_ROLES";
export const SET_ROLES = "SET_ROLES";
export const LOADING_ROLE = "LOADING_ROLE";
export const GET_ALL_USER = "GET_ALL_USER";
export const SET_ALL_USER = "SET_ALL_USER";
export const GET_ALL_USER_EXPORTS = "GET_ALL_USER_EXPORTS";
export const SET_ALL_USER_EXPORTS = "SET_ALL_USER_EXPORTS";
export const SET_ALL_ROLE_EXPORTS = "SET_ALL_ROLE_EXPORTS";
export const GET_ALL_ROLE_EXPORTS = "GET_ALL_ROLE_EXPORTS";
export const GET_USER = "GET_USER";
export const SET_USER = "SET_USER";
export const LOADING_USER = "LOADING_USER";
export const SET_USER_ERROR_CODE = "SET_USER_ERROR_CODE";
export const SET_USER_ERROR_MESSAGE = "SET_USER_ERROR_MESSAGE";

export const GET_ACCESS_RIGHT = "GET_ACCESS_RIGHT";
export const SET_ACCESS_RIGHT = "SET_ACCESS_RIGHT";
export const GET_TYPE = "GET_TYPE";
export const SET_TYPE = "SET_TYPE";
export const GET_PERMISSION = "GET_PERMISSION";
export const SET_PERMISSION = "SET_PERMISSION";
export const LOADING_PERMISSION = "LOADING_PERMISSION";
export const GET_TRANSACTION_TYPE = "GET_TRANSACTION_TYPE";
export const SET_TRANSACTION_TYPE = "SET_TRANSACTION_TYPE";
export const LOADING_TRANSACTION_TYPE = "LOADING_TRANSACTION_TYPE";

export const GET_BRANCHES = "GET_BRANCHES";
export const SET_BRANCHES = "SET_BRANCHES";
export const LOADING_BRANCHES = "LOADING_BRANCHES";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const PURGE_AUTH = "PURGE_AUTH";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const SET_CURRENT_PASSWORD_TOKEN = "SET_CURRENT_PASSWORD_TOKEN";
export const GET_TWO_FACTOR_AUTH = "GET_TWO_FACTOR_AUTH";
export const SET_TWO_FACTOR_AUTH = "SET_TWO_FACTOR_AUTH";
export const MUTATE_TWO_FACTOR_AUTH = "MUTATE_TWO_FACTOR_AUTH";

export const GET_DASHBOARD_STATS = "GET_DASHBOARD_STATS";
export const SET_DASHBOARD_STATS = "SET_DASHBOARD_STATS";
export const LOADING_DASHBOARD_STATS = "LOADING_DASHBOARD_STATS";
export const GET_ALL_INDICATORS = "GET_ALL_INDICATORS";
export const SET_ALL_INDICATORS = "SET_ALL_INDICATORS";
export const LOADING_ALL_INDICATORS = "LOADING_ALL_INDICATORS";

export const GET_CUSTOMER_TYPES = "GET_CUSTOMER_TYPES";
export const SET_CUSTOMER_TYPES = "SET_CUSTOMER_TYPES";
export const LOADING_CUSTOMER_TYPES = "LOADING_CUSTOMER_TYPES";

export const SET_LOAN_ACCOUNT_DETAILS_ERROR = "SET_LOAN_ACCOUNT_DETAILS_ERROR";
export const SET_ERROR_CODE = "SET_ERROR_CODE";

import ApiService from "@/core/services/api.service";

// mutation types
export const GET_ALL_TEMPLATES = "GET_ALL_TEMPLATES";
export const SetTemplates = "SetTemplates";
export const templateErrors = "templateErrors";

export const DELETE_TEMPLATE = "DELETE_TEMPLATE";
export const setDeleteSuccess = "setDeleteSuccess";

const state = {
  allTemplates: [],
  errors: [],
  success: "",
};

const getters = {
  getTemplates: (state) => state.allTemplates,
};

const mutations = {
  SetTemplates: (state, payload) => (state.allTemplates = payload),
  templateErrors: (state, payload) => (state.errors = payload),
  setDeleteSuccess: (state, payload) => (state.success = payload),
};

const actions = {
  GET_ALL_TEMPLATES({ commit }, { pageindex, pagesize, templateName }) {
    return new Promise((resolve, reject) => {
      commit(SetTemplates, []);
      ApiService.get(`Template/gettemplates/templateName/${pageindex}/${pagesize}?templateName=${templateName}`)
        .then((res) => {
          resolve(true);
          commit(SetTemplates, res.data);
        })
        .catch((error) => {
          reject(error);
          commit(templateErrors, error.response.data.message);
        });
    })
  },
  DELETE_TEMPLATE({ commit }, { templateKey }) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`Template/deleteTemplate/${templateKey}`)
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          commit(templateErrors, error.response.data.message);
          reject(error);
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};

import ApiService from "@/core/services/extension.service";

// /api/dashboardIndicators/CreateUpdateUserActivityTypeSettingsOnDashBoard
export const GET_ALL_EXTENSION_APP = "GET_ALL_EXTENSION_APP";
export const GET_EXTENSION_APP = "GET_EXTENSION_APP";
export const GET_LOADING_DATA = "GET_LOADING_DATA";
export const setLoading = "setLoading";
export const SetExtensionApp = "SetExtensionApp";
export const SetMyExtensionApp = "SetMyExtensionApp";
export const extensionAppErrors = "extensionAppErrors";

const state = {
    allExtensionApp: [],
    myExtension: "",
    errors: "",
    loading: true,
};

const getters = {
    getExtension: (state) => state.allExtensionApp,
    getMyExtension: (state) => state.myExtension,
    appLoading: (state) => state.loading,
};

const mutations = {
    SetExtensionApp: (state, payload) => (state.allExtensionApp = payload),
    SetMyExtensionApp: (state, payload) => (state.myExtension = payload),
    setLoading: (state, payload) => (state.loading = payload),
    extensionAppErrors: (state, payload) => (state.errors = payload),
};

const actions = {
    GET_ALL_EXTENSION_APP({ commit }) {
        commit(SetExtensionApp, []);
        commit(setLoading, true);
        ApiService.get(`App/getapps`)
            .then((res) => {
                commit(setLoading, false);
                commit(SetExtensionApp, res.data);
            })
            .catch((error) => {
                commit(setLoading, false);
                commit(extensionAppErrors, error);
            });
    },

    GET_EXTENSION_APP({ commit }, { generalAppKey }) {
        commit(SetMyExtensionApp, "");
        commit(setLoading, true);
        ApiService.get(`App/getapp/${generalAppKey}`)
            .then((res) => {
                commit(setLoading, false);
                commit(SetMyExtensionApp, res.data);
            })
            .catch((error) => {
                commit(setLoading, false);
                commit(extensionAppErrors, error);
            });
    },
};

export default {
    state,
    actions,
    mutations,
    getters,
};
import {
  DELETE_ACCOUNT_ERROR,
  DELETE_ACCOUNT_LOADING,
  DELETE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_ERROR,
  UPDATE_ACCOUNT_LOADING,
  baseUrl
} from './coreBankingTypes'

import CoreBankingService from "@/core/services/coreBanking.service";

export const GET_ALL_ACCOUNTS = "GET_ALL_ACCOUNTS";
export const setAllAccount = "setAllAccount";

export const POST_ACCOUNT = "POST_ACCOUNT";
export const setAllPostAccount = "setAllPostAccount";

export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const setAllUpdateAccount = "setAllUpdateAccount";

export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const setAllDeleteAccount = "setAllDeleteAccount";

export const GET_SAGE_TRANSACTIONS = "GET_SAGE_TRANSACTIONS";
export const setSageTransactions = "setSageTransactions";

export const setLoadingAccounting = "setLoadingAccounting";
export const setAccountingErrorMessage = "setAccountingErrorMessage";

export const setLoadingPostAccounting = "setLoadingPostAccounting";
export const setPostAccountingErrorMessage = "setPostAccountingErrorMessage";

export const setTransactionLogLoading = "setTransactionLogLoading";
export const setTransactionLogErrorMessage = "setTransactionLogErrorMessage";

export const GET_ALL_DATASET_MAPPING = "GET_ALL_DATASET_MAPPING";
export const GET_ALL_DATASET_MAPPING_ERROR = 'GET_ALL_DATASET_MAPPING_ERROR';
export const setDataSetMappingLoading = 'setDataSetMappingLoading';
export const GET_ALL_DATASET_MAPPING_SUCCESS = 'GET_ALL_DATASET_MAPPING_ERROR';
export const setDataSetMapping = 'setDataSetMapping';

export const POST_DATASET_MAPPING = "POST_DATASET_MAPPING";
export const POST_DATASET_MAPPING_ERROR = "POST_DATASET_MAPPING_ERROR";
export const POST_DATASET_MAPPING_LOADING = "POST_DATASET_MAPPING_LOADING";
export const POST_DATASET_MAPPING_SUCCESS = "POST_DATASET_MAPPING_SUCCESS";

export const GET_MAPPED_ACCOUNTS = "GET_MAPPED_ACCOUNTS";
export const SET_MAPPED_ACCOUNT = "SET_MAPPED_ACCOUNT";

export const UPDATE_DATASET_MAPPING = "UPDATE_DATASET_MAPPING";
export const DELETE_DATASET_MAPPING = "DELETE_DATASET_MAPPING";

export const GET_FAILED_TRANSACTIONS = "GET_FAILED_TRANSACTIONS";
export const setFailedTransactions = "setFailedTransactions";
export const setFailedTransactionsLoading = "setFailedTransactionsLoading";
export const setFailedTransactionsErrorMessage = "setFailedTransactionsErrorMessage";

export const SEND_GUID_UPLOADED_DATA = "SEND_GUID_UPLOADED_DATA";
export const setGuidUploadedData = "setGuidUploadedData";
export const setGuidUploadedDataLoading = "setGuidUploadedDataLoading";
export const setGuidUploadedDataErrorMessage = "setGuidUploadedDataErrorMessage";

const state = {
  allAccounts: [],
  allDataSets: [],
  allFailedTransactions: [],
  allDataSetsSuccess: null,
  errorAccounting: '',
  errorPostAccounting: '',
  allSageTransactions: [],
  allMappedAccounts: [],
  allGuidData: [],
  errorCode: '',
  error: '',
  mappingError: '',
  errorAllDataSets: '',
  failedTransactionsError: '',
  uploadGuidError: '',
  loadingAccounts: false,
  loadingPostAccounts: false,
  loadingPostDataSet: false,
  loadingDataSets: false,
  loadingTransactionLogs: false,
  loadingFailedTransactions: false,
  loadingGuidData: false,
}

const mutations = {
  //Get all accounts
  setAllAccount: (state, payload) => {
    state.allAccounts = payload;
  },

  setLoadingAccounting: (state, payload) => {
    state.loadingAccounts = payload;
  },

  setAccountingErrorMessage: (state, payload) => {
    state.errorAccounting = payload;
  },

  //post accounts
  setAllPostAccount: (state, payload) => {
    state.allAccounts = payload;
  },

  setLoadingPostAccounting: (state, payload) => {
    state.loadingPostAccounts = payload;
  },

  setPostAccountingErrorMessage: (state, payload) => {
    state.errorPostAccounting = payload;
  },

  //Update Account
  setAllUpdateAccount: (state, payload) => {
    state.allAccounts = payload;
  },

  [UPDATE_ACCOUNT_LOADING](state, payload) {
    state.loadingAccounts = payload;
  },

  [UPDATE_ACCOUNT_ERROR](state, payload) {
    state.error = payload;
  },
  
  //Delete Account
  setAllDeleteAccount: (state, payload) => {
    state.allAccounts = payload;
  },

  [DELETE_ACCOUNT_LOADING](state, payload) {
    state.loadingAccounts = payload;
  },

  [DELETE_ACCOUNT_ERROR](state, payload) {
    state.error = payload;
  },

  //Get all data sets
  [GET_ALL_DATASET_MAPPING_SUCCESS](state, payload) {
    state.allDataSets = payload;
  },

  setDataSetMapping: (state, payload) => {
    state.allDataSets = payload;
  },

  setDataSetMappingLoading(state, payload) {
    state.loadingDataSets = payload;
  },

  [GET_ALL_DATASET_MAPPING_ERROR](state, payload) {
    state.errorAllDataSets = payload;
  },

  //map data sets
  POST_DATASET_MAPPING_SUCCESS(state, payload) {
    state.allDataSetsSuccess = payload;
  },

  POST_DATASET_MAPPING_LOADING(state, payload) {
    state.loadingPostDataSet = payload;
  },

  POST_DATASET_MAPPING_ERROR(state, payload) {
    state.mappingError = payload;
  },

  // Set mapped account
  SET_MAPPED_ACCOUNT: (state, payload) => {
    state.allMappedAccounts = payload;
  },

  //get transaction logs
  setSageTransactions: (state, payload) => {
    state.allSageTransactions = payload;
  },

  setTransactionLogLoading: (state, payload) => {
    state.loadingTransactionLogs = payload;
  },

  setTransactionLogErrorMessage: (state, payload) => {
    state.error = payload;
  },

  // Failed Transactions
  setFailedTransactions: (state, payload) => {
    state.allFailedTransactions = payload;
  },

  setFailedTransactionsLoading: (state, payload) => {
    state.loadingFailedTransactions = payload;
  },

  setFailedTransactionsErrorMessage: (state, payload) => {
    state.failedTransactionsError = payload;
  },

  // GUID Uploaded Data
  setGuidUploadedData: (state, payload) => {
    state.allGuidData = payload;
  },

  setGuidUploadedDataLoading: (state, payload) => {
    state.loadingGuidData = payload;
  },

  setGuidUploadedDataErrorMessage: (state, payload) => {
    state.uploadGuidError = payload;
  },
}

const actions = {
  GET_ALL_ACCOUNTS({ commit }) {
    commit(setLoadingAccounting, true);
    commit(setAccountingErrorMessage, "");
    CoreBankingService.get(`${baseUrl}SageAccount/getaccounts`)
    .then((res) => {
      commit(setLoadingAccounting, false);
      commit(setAllAccount, res.data.data);
    })
    .catch((error) => {
      commit(setLoadingAccounting, false);
      commit(setAccountingErrorMessage, error.response.data.message);
    })
  },

  POST_ACCOUNT({ commit }, payload) {
    commit(setPostAccountingErrorMessage, "");
    commit(setLoadingPostAccounting, true);
    return new Promise((resolve, reject) => {
      CoreBankingService.post(`${baseUrl}SageAccount/accountupload`, payload)
      .then((res) => {
        commit(setLoadingPostAccounting, false);
        commit(setAllPostAccount, res.data);
        resolve(true);
      })
      .catch((error) => {
        commit(setLoadingPostAccounting, false);
        commit(setPostAccountingErrorMessage, error.response.data.message);
        reject(error);
      })
    });
  },

  UPDATE_ACCOUNT({ commit }, payload) {
    commit(setPostAccountingErrorMessage, "");
    commit(setLoadingPostAccounting, true);
    return new Promise((resolve, reject) => {
      CoreBankingService.post(`${baseUrl}SageAccount/updateaccount`, payload)
      .then((res) => {
        commit(setLoadingPostAccounting, false);
        commit(setAllUpdateAccount, res.data);
        resolve(true);
      })
      .catch((error) => {
        commit(setLoadingPostAccounting, false);
        commit(setPostAccountingErrorMessage, error.response.data.message);
        reject(error);
      })
    })
  },

  DELETE_ACCOUNT({ commit }, payload) {
    commit(setPostAccountingErrorMessage, "");
    commit(setLoadingPostAccounting, true);
    return new Promise((resolve, reject) => {
      CoreBankingService.delete(`${baseUrl}SageAccount/deleteaccount?request_key=${payload}`)
      .then((resp) => {
        commit(setLoadingPostAccounting, false);
        commit(DELETE_ACCOUNT_SUCCESS, resp.data);
        resolve(true);
      })
      .catch((error) => {
        commit(setPostAccountingErrorMessage, error.response.data.message);
        commit(setLoadingPostAccounting, false);
        reject(error);
      })
    })
  },

  GET_MAPPED_ACCOUNTS({ commit }) {
    CoreBankingService.get(`${baseUrl}SageAccountMapping/getdatasetaccountmapping`)
    .then((res) => {
      commit(GET_ALL_DATASET_MAPPING_ERROR, '');
      commit(SET_MAPPED_ACCOUNT, res.data.data);
    })
    .catch((error) => {
      commit(GET_ALL_DATASET_MAPPING_ERROR, error.response.data.message);
    })
  },

  GET_ALL_DATASET_MAPPING({ commit }) {
    commit(setDataSetMappingLoading, true);
    CoreBankingService.get(`${baseUrl}SagePosting/getdatasetenumdata`)
    .then((res) => {
      commit(setDataSetMappingLoading, false);
      commit(GET_ALL_DATASET_MAPPING_ERROR, '');
      commit(setDataSetMapping, res.data);
    })
    .catch((error) => {
      commit(setDataSetMappingLoading, false);
      commit(GET_ALL_DATASET_MAPPING_ERROR, error.response.data.message);
    })
  },

  POST_DATASET_MAPPING({ commit }, payload) {
    commit(POST_DATASET_MAPPING_ERROR, "");
    commit(POST_DATASET_MAPPING_LOADING, true);
    return new Promise((resolve, reject) => {
      CoreBankingService.post(`${baseUrl}SageAccountMapping/datasetaccountmapping`, payload)
      .then(() => {
        commit(POST_DATASET_MAPPING_LOADING, false);
        commit(POST_DATASET_MAPPING_SUCCESS, 'Data mapping request is successful');
        resolve(true);
      })
      .catch((error) => {
        commit(POST_DATASET_MAPPING_LOADING, false);
        commit(POST_DATASET_MAPPING_ERROR, error.response.data.message);
        reject(error);
      })
    });
  },

  UPDATE_DATASET_MAPPING({commit}, payload) {
    commit(POST_DATASET_MAPPING_ERROR, "");
    commit(POST_DATASET_MAPPING_LOADING, true);
    return new Promise((resolve, reject) => {
      CoreBankingService.post(`${baseUrl}SageAccountMapping/updatedatasetaccountmapping`, payload)
      .then(() => {
        commit(POST_DATASET_MAPPING_LOADING, false);
        commit(POST_DATASET_MAPPING_SUCCESS, 'Data mapping update request is successful');
        resolve(true);
      })
      .catch((error) => {
        commit(POST_DATASET_MAPPING_LOADING, false);
        commit(POST_DATASET_MAPPING_ERROR, error.response.data.message);
        reject(error);
      })
    });
  },

  DELETE_DATASET_MAPPING({ commit }, payload) {
    commit(setPostAccountingErrorMessage, "");
    return new Promise((resolve, reject) => {
      CoreBankingService.delete(`${baseUrl}SageAccountMapping/deleteaccountmapping?request_key=${payload}`)
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        commit(setPostAccountingErrorMessage, error.response.data.message);
        reject(error);
      })
    });
  },

  GET_SAGE_TRANSACTIONS({ commit }, 
    { 
      pageindex, 
      pagesize, 
      SortDescending, 
      status,
      CreationDateFrom,
      CreationDateTo,
      SortColumn,
    }
  ) {
    commit(setTransactionLogErrorMessage, "");
    commit(setTransactionLogLoading, true);
    commit(setSageTransactions, []);
    CoreBankingService.get(`${baseUrl}SagePosting/getsagetransactions?pageindex=${pageindex}
      &pagesize=${pagesize}
      &SortDescending=${SortDescending}
      &SortColumn=${SortColumn === null ? "" : SortColumn}
      &status=${status === null ? "" : status}
      &CreationDateFrom=${CreationDateFrom === null ? "" : CreationDateFrom}
      &CreationDateTo=${CreationDateTo === null ? "" : CreationDateTo}
    `)
    .then((res) => {
      commit(setTransactionLogLoading, false);
      commit(setSageTransactions, res.data);
    })
    .catch((error) => {
      commit(setTransactionLogLoading, false);
      commit(setTransactionLogErrorMessage, error.response.data.message);
    })
  },

  GET_FAILED_TRANSACTIONS({ commit }, payload) {
    commit(setFailedTransactionsErrorMessage, "");
    commit(setFailedTransactionsLoading, true);
    commit(setFailedTransactions, []);
    CoreBankingService.post(`${baseUrl}SagePosting/getsagetransactionsdownload`, payload)
    .then((res) => {
      commit(setFailedTransactionsErrorMessage, "");
      commit(setFailedTransactionsLoading, false);
      commit(setFailedTransactions, res.data);
    })
    .catch((error) => {
      commit(setDataSetMappingLoading, false);
      commit(setFailedTransactionsErrorMessage, error.response.data.message);
    })
  },

  SEND_GUID_UPLOADED_DATA({ commit }, payload) {
    commit(setGuidUploadedDataErrorMessage, "");
    commit(setGuidUploadedDataLoading, true);
    commit(setGuidUploadedData, []);
    if (payload.request_keys.length) {
      CoreBankingService.post(`${baseUrl}SagePosting/sagetransactionsmanualposting`, payload)
        .then((res) => {
          commit(setGuidUploadedDataErrorMessage, "");
          commit(setGuidUploadedDataLoading, false);
          commit(setGuidUploadedData, res.data.data);
        })
        .catch((error) => {
          commit(setGuidUploadedDataLoading, false);
          commit(setGuidUploadedDataErrorMessage, error.response.data.message);
        });
    }
  },
}

export default {
  state,
  actions,
  mutations
};

import ApiService from "@/core/services/api.service";

// mutation types
export const GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS";
export const GET_ALL_GROUP_NOTIFICATIONS = "GET_ALL_GROUP_NOTIFICATIONS";
export const GET_LOADING_DATA = "GET_LOADING_DATA";
export const setLoading = "setLoading";
export const SetNotification = "SetNotification";
export const SetGroupNotification = "SetGroupNotification";
export const notificationErrors = "notificationErrors";

export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";

const state = {
  allNotifs: [],
  allGroupNotifications: [],
  errors: [],
  loading: true,
};

const getters = {
  getNotifications: (state) => state.allNotifs,
  getGroupNotifications: (state) => state.allGroupNotifications,
  // getLoading: (state) => state.loading,
};

const mutations = {
  SetNotification: (state, payload) => (state.allNotifs = payload),
  SetGroupNotification: (state, payload) =>
    (state.allGroupNotifications = payload),
  setLoading: (state, payload) => (state.loading = payload),
  notificationErrors: (state, payload) => (state.errors = payload),
};

const actions = {
  GET_ALL_NOTIFICATIONS({ commit }, { pageindex, pagesize, EventName }) {
    return new Promise((resolve, reject) => {
      commit(SetNotification, []);
      ApiService.get(
        `NotificationMessage/getnotifications/${pageindex}/${pagesize}?EventName=${EventName}`
      )
        .then((res) => {
          resolve(true)
          commit(SetNotification, res.data);
        })
        .catch((error) => {
          reject(false);
          commit(notificationErrors, error.res.data.message);
        });
    });
  },
  GET_ALL_GROUP_NOTIFICATIONS({ commit }, { pageindex, pagesize, status, searchName }) {
    commit(SetGroupNotification, []);
    commit(setLoading, true);
    ApiService.post(
      `NotificationMessage/AllPredefinedNotificationGroup?pageIndex=${pageindex}&pageSize=${pagesize}&status=${status}&searchName=${searchName}`,
      { pageindex, pagesize, status, searchName }
    )
      .then((res) => {
        commit(setLoading, false);
        commit(SetGroupNotification, res.data);
      })
      .catch((error) => {
        commit(setLoading, false);
        commit(notificationErrors, error.response.data.message);
      });
  },
  DELETE_NOTIFICATION({ commit }, { notificationKey }) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`NotificationMessage/DeleteNotification/${notificationKey}`)
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          commit(notificationErrors, error.response.data.message);
          reject(error);
        });
    });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};

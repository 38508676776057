import { LOGOUT, REFRESH_TOKEN } from "./store/types";

import JwtService from "@/core/services/jwt.service";
import Store from "@/core/services/store";
import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";
import router from "@/router";

/**
 * Service to call HTTP request via Axios
 */
const LoanApiService = {
  init() {
    // Add a request interceptor
    axios.interceptors.request.use(
      (config) => {
        const token = JwtService.getToken();
        if (token) {
          config.headers["Authorization"] = `Bearer ${JwtService.getToken()}`;
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    //Add a response interceptor

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      function (error) {
        if (error?.response?.status !== 401) {
          return Promise.reject(error);
        }
        // Logout user if token refresh didn't work or user is disabled
        if (error.config.url.includes("ProleanUser/refreshtoken")) {
          Store.dispatch(LOGOUT);
          router.push("/auth/login");
          return Promise.reject(error);
        }
        // refresh token
        // Try request again with new token
        Store.dispatch(REFRESH_TOKEN);
      }
    );

    Vue.use(VueAxios, axios);
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_BASE_LOAN_URL;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch((error) => {
      if (error.hasOwnProperty("response") && error?.response) {
        if (error?.response.hasOwnProperty("data")) {
          throw new Error(`ApiService ${error?.response.data.message}`);
        }
      } else throw new Error(`ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = null) {
    this.setHeader();
    return Vue.axios
      .get(`${resource}${slug ? "/" : ""}${slug ? slug : ""}`)
      .catch((error) => {
        if (error.hasOwnProperty("response") && error?.response) {
          if (error?.response.hasOwnProperty("data")) {
            throw new Error(`${error?.response.data.message}`);
          }
        } else throw new Error(`${error}`);
      });
  },
  getall(resource, slug = null) {
    this.setHeader();
    return Vue.axios.get(`${resource}${slug ? "/" : ""}`).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    this.setHeader();
    return Vue.axios.post(`${resource}`, params);
  },

  postFormData(resource, params) {
    this.setHeader();
    return Vue.axios.post(`${resource}`, params, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    this.setHeader();
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    this.setHeader();
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the PATCH HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  patch(resource, params) {
    this.setHeader();
    return Vue.axios.patch(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource, slug = null) {
    this.setHeader();
    return Vue.axios
      .delete(`${resource}${slug ? "/" : ""}${slug ? slug : ""}`)
      .catch((error) => {
        if (error.hasOwnProperty("response") && error?.response) {
          if (error?.response.hasOwnProperty("data")) {
            throw new Error(`ApiService ${error?.response.data.message}`);
          }
        } else throw new Error(`ApiService ${error}`);
      });
  },

  del(resource, params) {
    this.setHeader();
    return Vue.axios.delete(`${resource}`, {
      data: {
        ...params
      }
    });
  },

};

export default LoanApiService;

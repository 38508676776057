import {
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  GET_TWO_FACTOR_AUTH,
  LOGIN,
  LOGOUT,
  MUTATE_TWO_FACTOR_AUTH,
  PURGE_AUTH,
  REFRESH_TOKEN,
  RESET_PASSWORD,
  SET_CURRENT_PASSWORD_TOKEN,
  SET_TWO_FACTOR_AUTH,
} from "./types";

import ApiService from "@/core/services/access.service";
import CPService from "@/core/services/change.password.service";
import JwtService from "@/core/services/jwt.service";
import Vue from "vue";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
  generatedQRCode: {},
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    const url = window.location.host;
    return new Promise((resolve, reject) => {
      ApiService.post(
        `ProleanUser/UserLogin`,
        credentials,
        Vue.axios.defaults.headers.common[
          "domain"
        ] = `${url}`
      )
        .then(({ data }) => {
          if (data.data.hasChangedPassword) {
            if (data.data.enabled2FA) {
              context.commit(SET_CURRENT_PASSWORD_TOKEN, data.data);
            } else {
              context.commit(SET_AUTH, data.data);
            }
          } else {
            context.commit(SET_CURRENT_PASSWORD_TOKEN, data.data);
          }
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [FORGOT_PASSWORD](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        `ProleanUser/initiatepasswordreset?userName=${credentials}`
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [GET_TWO_FACTOR_AUTH](context) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `Totp/generate-otp`
      )
        .then(({ data }) => {
          context.commit(MUTATE_TWO_FACTOR_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [RESET_PASSWORD](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        `ProleanUser/verifypasswordreset`,
        credentials
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [SET_TWO_FACTOR_AUTH](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post(`Totp/verifyotp`, credentials)
        .then(({ data }) => {
          if (CPService.getCPToken()) {
            context.commit(SET_AUTH, CPService.getCPToken());
          }
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [CHANGE_PASSWORD](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        `ProleanUser/changepassword`,
        credentials
      )
        .then(({ data }) => {
          if (CPService.getCPToken() && !CPService.getCPToken().enabled2FA) {
            context.commit(SET_AUTH, CPService.getCPToken());
          }
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [REFRESH_TOKEN](context) {
    return new Promise((resolve, reject) => {
      ApiService.post(`ProleanUser/refreshtoken`, {
        refreshToken: context.state.user.refreshToken,
      })
        .then(({ data }) => {
          context.commit(REFRESH_TOKEN, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.get("verify")
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    JwtService.saveToken(state.user.access_Token);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
  [REFRESH_TOKEN](state, user) {
    state.isAuthenticated = true;
    state.errors = {};
    JwtService.saveToken(user.access_Token);
  },
  [SET_CURRENT_PASSWORD_TOKEN](state, user) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    CPService.saveCPToken(JSON.stringify(user));
  },
  [MUTATE_TWO_FACTOR_AUTH] (state, code) {
    state.generatedQRCode = code;
  }
};

export default {
  state,
  actions,
  mutations,
  getters,
};

import {
    GET_ALL_INDICATORS,
    GET_CUSTOMER_TYPES,
    GET_DASHBOARD_STATS,
    LOADING_ALL_INDICATORS,
    LOADING_CUSTOMER_TYPES,
    LOADING_DASHBOARD_STATS,
    SET_ALL_INDICATORS,
    SET_CUSTOMER_TYPES,
    SET_DASHBOARD_STATS
} from "./types"

/* eslint-disable */
import ApiService from "@/core/services/api.service";
import GeneralApiService from "@/core/services/general.service";
import axios from "axios";

// mutation types
export const GET_ALL_PRODUCT_TYPE = "GET_ALL_PRODUCT_TYPE";
export const setProductType = "setProductType";

export const GET_ALL_PRODUCT_CATEGORY = "GET_ALL_PRODUCT_CATEGORY";
export const setProductCategory = "setProductCategory";

export const GET_GROUP_TYPES = "GET_GROUP_TYPES";
export const setGroupTypes = "setGroupTypes";

export const GET_ALL_CURRENCY = "GET_CURRENCY"
export const setCurrency = "setCurrency";

export const GET_ALL_INTEREST_CHARGE = "GET_ALL_INTEREST_CHARGE";
export const setInterestCharge = "setInterestCharge";

export const GET_ALL_INTEREST_RATE = "GET_ALL_INTEREST_RATE";
export const setInterestRate = "setInterestRate";

export const GET_ALL_ID = "GET_ALL_ID";
export const setAllID = "setAllID";

export const GET_DAYS_IN_YEARS = "GET_DAYS_IN_YEARS";
export const setDaysInYears = "setDaysInYears";

export const GET_ACCOUNT_BALANCE_CALCULATION = "GET_ACCOUNT_BALANCE_CALCULATION";
export const setAccountBalanceCalculation = "setAccountBalanceCalculation";

export const GET_ACCOUNT_INTEREST = "GET_ACCOUNT_INTEREST";
export const setAccountInterest = "setAccountInterest";

export const GET_MONTHS = "GET_MONTHS";
export const setMonths = "setMonths";

export const GET_FEE_TYPE = "GET_FEE_TYPE";
export const setFeeType = "setFeeType";

export const GET_APPLIED_DATE_METHOD = "GET_APPLIED_DATE_METHOD";
export const setAppliedDateMethod = "setAppliedDateMethod";

export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const createStatus = "createStatus";
export const statusCreated = "statusCreated";

export const GET_ALL_DEPOSITS = "GET_ALL_DEPOSITS";
export const setAllDeposits = "setAllDeposits";

export const GET_PRODUCT_AVAILABILITY = "GET_PRODUCT_AVAILABILITY";
export const setAvailableProduct = "setAvailableProduct";

export const postRequest = "postRequest";

export const depositErrors = "depositErrors";

export const GET_DEPOSIT_PRODUCT = "GET_DEPOSIT_PRODUCT";
export const setDepositProduct = "setDepositProduct";

export const UPDATE_DEPOSIT_PRODUCT = "UPDATE_DEPOSIT_PRODUCT";
export const deactivatedStatus = "deactivatedStatus";

export const DEACTIVATE_PRODUCT = "DEACTIVATE_PRODUCT";
export const setDeactivateStatus = "setDeactivateStatus";

export const GET_TERM_UNIT = "GET_TERM_UNIT";
export const setTermUnit = "setTermUnit";

export const GET_ALL_DEPOSITS_ACCOUNTS = "GET_ALL_DEPOSITS_ACCOUNTS";
export const setAllDepositsAccounts = "setAllDepositsAccounts";

export const loadingDepositData = "loadingDepositData";

export const GET_ALL_DEPOSITS_EXPORTS = "GET_ALL_DEPOSITS_EXPORTS";
export const setAllDepositsExports = "setAllDepositsExports";

export const GET_DEPOSIT_ACCOUNT = "GET_DEPOSIT_ACCOUNT";
export const setDepositAccount = "setDepositAccount";

export const GET_DEPOSIT_ACCOUNT_DETAILS_ERROR = "GET_DEPOSIT_ACCOUNT_DETAILS_ERROR";
export const SET_ERROR_CODE = "SET_ERROR_CODE";

export const setShowNoData = setShowNoData;

const baseUrl = process.env.VUE_APP_API_BASE_DEPOSIT_URL;

const product_type_uri = `${baseUrl}ProductSavingsEnum/allproducttype`;
const product_category_uri = `${baseUrl}ProductSavingsEnum/allproductcategory`;
// const currency_uri = `${baseUrl}ProductSavingsEnum/allcurrencyenum';
const interest_charge_uri = `${baseUrl}ProductSavingsEnum/allinterestcharge`;
const interest_rate_uri = `${baseUrl}ProductSavingsEnum/allinterestrateterm`;
const id_type_uri =`${baseUrl}ProductSavingsEnum/allidtype`;
const days_in_years_uri = `${baseUrl}ProductSavingsEnum/GetYearDaysEnum`;
const account_balance_uri = `${baseUrl}ProductSavingsEnum/allaccountcaculationenums`;
const account_interest_uri = `${baseUrl}ProductSavingsEnum/GetAccountInterestPaymentEnum`;
const month_uri = `${baseUrl}ProductSavingsEnum/GetMonthEnum`;
const fee_type_uri = `${baseUrl}ProductSavingsEnum/GetFeeTypeEnum`;
const applied_date_uri = `${baseUrl}ProductSavingsEnum/GetAppliedDateMethodEnum`;
const create_product_uri = `${baseUrl}DepositAdministration/Create`;
// const product_uri = `${baseUrl}DepositProductForm/GetDepositProductByProductId?ProductId=`;
const product_availability_uri = `${baseUrl}ProductSavingsEnum/GetProductAvalaibilityEnum`;
const term_unit_uri = `${baseUrl}ProductSavingsEnum/getTermUnitMeasureEnum`;

const state = {
    productTypes: [],
    productCat: [],
    productCurrency: [],
    productInterestCharge: [],
    productInterestRate: [],
    IDType: [],
    DaysInYears: [],
    AccountBalanceCalculation: [],
    AccountInterest: [],
    getMonths: [],
    productFeeType: [],
    appliedDateMethod: [],
    created_status: '',
    status_created: [],
    getAllProducts: [],
    getAllDeposits: [],
    availableProducts: [],
    errors: [],
    state: '',
    depositProduct: [],
    updatedProduct: [],
    termUnit: [],
    depositAccounts: [],
    depositExports: [],
    depositAccount: [],
    dashboardStats: {},
    allIndicators: [],
    customerTypes: [],
    groupTypes: [],
    loadingdashboardStats: false,
    loadingAllIndicators: false,
    loadingCustomerTypes: false,
    depositError: '',
    depositErrorCode: '',
    loadingDepositData: false,
    showNoData: false,
};
const getters = {
    allProductTypes: state => state.productTypes,
    allproductcategory: state => state.productCat,
    allCurrency: state => state.productCurrency,
    allInterestCharge: state => state.productInterestCharge,
    allInterestRate: state => state.productInterestRate,
    allIDTypes: state => state.IDType,
    allDaysInYears: state => state.DaysInYears,
    allAccountBalanceCalculation: state => state.AccountBalanceCalculation,
    allAccountInterests: state => state.AccountInterest,
    allMonths: state => state.getMonths,
    allFeeTypes: state => state.productFeeType,
    allAppliedDates: state => state.appliedDateMethod,
    isCreating: state => state.created_status,
    hasCreated: state => state.status_created,
    allProducts: state => state.getAllProducts,
    allAvailableProducts: state => state.availableProducts,
    allDeposits: state => state.getAllDeposits,
    allErrors: state => state.errors,
    depositProd: state => state.depositProduct,
    updatedProd: state => state.updatedProduct,
    alltermUnits: state => state.termUnit,
    allDepositAccounts: state => state.depositAccounts,
    allDepositExports: state => state.depositExports,
    getDepositAccount: state => state.depositAccount,
    getGroupTypes: state => state.groupTypes,
    showNoData: state => state.showNoData
}; 
const mutations = {
    setProductType: (state, activity) => state.productTypes = activity,
    setProductCategory: (state, activity) => state.productCat = activity,
    setCurrency: (state, activity) => state.productCurrency = activity,
    setInterestCharge: (state, activity) => state.productInterestCharge = activity,
    setInterestRate: (state, activity) => state.productInterestRate = activity,
    setAllID: (state, activity) => state.IDType = activity,
    setDaysInYears: (state, activity) => state.DaysInYears = activity,
    setAccountBalanceCalculation: (state, activity) => state.AccountBalanceCalculation = activity,
    setAccountInterest: (state, activity) => state.AccountInterest = activity,
    setMonths: (state, activity) => state.getMonths  = activity,
    setFeeType: (state, activity) => state.productFeeType = activity,
    setAppliedDateMethod: (state, activity) => state.appliedDateMethod = activity,
    createStatus: (state) => state.created_status = 'loading',
    statusCreated: (state, params) => state.status_created = params,
    setAllProducts: (state, params) => state.getAllProducts = params,
    setAvailableProduct: (state, activity) => state.availableProducts = activity,
    depositErrors: (state, error) => state.errors = error,
    setAllDeposits: (state, activity) => state.getAllDeposits = activity,
    postRequest: (state) => state.status = 'loading',
    setDepositProduct: (state, params) => state.depositProduct = params,
    setAllDepositsAccounts: (state, params) => state.depositAccounts = params,
    setAllDepositsExports: (state, params) => state.depositExports = params,
    setDepositAccount: (state, params) => state.depositAccount = params,
    setGroupTypes: (state, params) => state.groupTypes = params,
    loadingDepositData: (state, params) => state.loadingDepositData = params,
    deactivatedStatus: (state, deactivatedStatus) => {
        const i = state.getAllDeposits.data.findIndex(getAllDeposit => getAllDeposit.id === deactivatedStatus.id);
        if(index !== -1) {
            state.getAllDeposits.data.splice(i, 1, deactivatedStatus);
        }
    },
    setTermUnit: (state, params) => state.termUnit = params,
    setShowNoData: (state, params) => state.showNoData = params,
    [SET_DASHBOARD_STATS](state, payload) {
        state.dashboardStats = payload;
    },
    [LOADING_DASHBOARD_STATS](state, payload) {
        state.loadingdashboardStats = payload;
    },
    [SET_ALL_INDICATORS](state, payload) {
        state.allIndicators = payload;
    },
    [LOADING_ALL_INDICATORS](state, payload) {
        state.loadingAllIndicators = payload;
    },
    [SET_CUSTOMER_TYPES](state, payload) {
        state.customerTypes = payload
    },
    [LOADING_CUSTOMER_TYPES](state, payload) {
        state.loadingCustomerTypes = payload;
    },
    SET_ERROR_CODE: (state, params) => state.depositErrorCode = params,
    GET_DEPOSIT_ACCOUNT_DETAILS_ERROR: (state, params) => state.depositError = params,
};
const actions = {
      GET_ALL_PRODUCT_TYPE({ commit }) {
          ApiService.get(product_type_uri)
              .then(response => {
                    commit(setProductType, response.data);
              })
              .catch( error => {
                    commit(depositErrors, error.message)
              })
      },
      GET_ALL_PRODUCT_CATEGORY({commit}) {
          ApiService.get(product_category_uri)
                    .then(res => {
                        commit(setProductCategory, res.data);
                    })
                    .catch( error => {
                        commit(depositErrors, error.message)
                    })
      },
      GET_ALL_CURRENCY({ commit }) {
        GeneralApiService.get('Currency?pageIndex=1&pageSize=2000&')
                    .then( response => {
                        commit(setCurrency, response.data)
                    })
                    .catch( error => {
                        commit(depositErrors, error.message)
                    })
      },
      GET_ALL_INTEREST_CHARGE({ commit }) {
          ApiService.get(interest_charge_uri)
                    .then( response => {
                        commit(setInterestCharge, response.data)
                    })
                    .catch (error => {
                        commit(depositErrors, error.message)
                    })
      },
      GET_ALL_INTEREST_RATE({ commit }) {
        ApiService.get(interest_rate_uri)
                  .then( response => {
                      commit(setInterestRate, response.data)
                  })
                  .catch (error => {
                    commit(depositErrors, error.message)
                  })
        },
        GET_ALL_ID({ commit }) {
            ApiService.get(id_type_uri)
                        .then( response => {
                            commit(setAllID, response.data)
                        })
                        .catch (error => {
                            commit(depositErrors, error.message)
                        })
        },
        GET_DAYS_IN_YEARS({ commit }) {
            ApiService.get(days_in_years_uri)
                        .then( response => {
                            commit(setDaysInYears, response.data)
                        })
                        .catch(error => {
                            commit(depositErrors, error.message)
                        })
        },
        GET_ACCOUNT_BALANCE_CALCULATION({ commit }) {
            ApiService.get(account_balance_uri)
                        .then( response => {
                            commit(setAccountBalanceCalculation, response.data)
                        })
                        .catch(error => {
                            commit(depositErrors, error.message)
                        })
        },
        GET_ACCOUNT_INTEREST({ commit }) {
            ApiService.get(account_interest_uri)
                        .then( response => {
                            commit(setAccountInterest, response.data)
                        })
                        .catch(error => {
                            commit(depositErrors, error.message)
                        })
        },
        GET_MONTHS({ commit }) {
            ApiService.get(month_uri)
                        .then( response => {
                            commit(setMonths, response.data)
                        })
                        .catch(error => {
                            commit(depositErrors, error.message)
                        })
        },
        GET_FEE_TYPE({ commit }) {
            ApiService.get(fee_type_uri)
                        .then( response => {
                            commit(setFeeType, response.data)
                        })
                        .catch(error => {
                            commit(depositErrors, error.message)
                        })
        },
        GET_APPLIED_DATE_METHOD({ commit }) {
            ApiService.get(applied_date_uri)
                        .then( response => {
                            commit(setAppliedDateMethod, response.data)
                        })
                        .catch( error => {
                            commit(depositErrors, error.message)
                        })
        },
        CREATE_PRODUCT({commit}, payload) {
            return new Promise((resolve, reject) => {
                ApiService.post(create_product_uri, payload)
                            .then( data  => {
                                commit(statusCreated, data)
                                resolve(true)
                            })
                            .catch( error => {
                                commit(depositErrors, error.message)
                                reject(error)
                            })
            })
        },
        GET_ALL_DEPOSITS({ commit}, {pageindex, pagesize, status, sort, search}) {
            return new Promise((resolve, reject) => {
                ApiService.get(`DepositAdministration/GetActiveDeactiveDepositProduct?PageIndex=${pageindex}&PageSze=${pagesize}&SortBy=${sort}&Status=${status}&searchString=${search}`)
                        .then( response => {
                            commit(setAllDeposits, response.data)
                            resolve(true)
                        })
                        .catch(error => {
                            commit(depositErrors, error.message)
                            reject(error)
                        })
            })
        },
        GET_ALL_DEPOSITS_ACCOUNTS({ commit }, payload) {
            commit(loadingDepositData,true)
            if (payload.isFromFilter) {
                return new Promise((resolve, reject) => {
                    GeneralApiService.post(`GeneralFilter/ApplyFilter`, payload)
                        .then(response => {
                            commit(setAllDepositsAccounts, response.data)
                            commit(SET_ERROR_CODE, '')
                            commit(loadingDepositData,false)
                            commit(depositErrors, '')
                            resolve(true)
                        })
                        .catch(error => {
                            commit(loadingDepositData,false)
                            commit(depositErrors, error.response.message)
                            reject(error)
                        })
                })
            } else {
                return new Promise((resolve, reject) => {
                    ApiService.post(`DepositAccount/GetFilteredDepositAccount`, payload)
                        .then(response => {
                            commit(setAllDepositsAccounts, response.data)
                            commit(setAllDepositsExports, response.data)
                            commit(loadingDepositData,false)
                            commit(SET_ERROR_CODE, '')
                            commit(depositErrors, '')
                            resolve(true)
                        })
                        .catch(error => {
                            commit(loadingDepositData,false)
                            commit(depositErrors, error.response.message)
                            reject(error)
                        })
                })
            }
        },
        GET_ALL_DEPOSITS_EXPORTS({ commit }, payload) {
            return new Promise((resolve, reject) => {
                GeneralApiService.post(`GeneralFilter/ApplyFilter`, payload)
                    .then(response => {
                        commit(setAllDepositsExports, response.data)
                        commit(SET_ERROR_CODE, '')
                        commit(depositErrors, '')
                        resolve(true)
                    })
                    .catch(error => {
                        commit(depositErrors, error.response.message)
                        reject(error)
                    })
            })
        },
        GET_PRODUCT_AVAILABILITY({commit}) {
            ApiService.get(product_availability_uri)
                        .then( response => {
                            commit(setAvailableProduct, response.data)
                        })
                        .catch(error => {
                            commit(depositErrors, error.message)
                        })
        },
        GET_DEPOSIT_PRODUCT({commit},{id}) {
            ApiService.get(`DepositAdministration/GetDepositProductByProductId/${id}`)
                        .then( response => {
                            commit(setDepositProduct, response.data)
                        })
                        .catch( error => {
                            commit(depositErrors, error.message)
                        })
        },
        GET_DEPOSIT_ACCOUNT({commit},{savingsAccountKey}) {
            // return new Promise((resolve, reject) => {
                ApiService.get(`DepositAccount/GetDepositAccountBySavingAccountKey/${savingsAccountKey}`)
                    .then((response) => {
                        commit(setDepositAccount, response.data)
                        commit(GET_DEPOSIT_ACCOUNT_DETAILS_ERROR, '')
                        commit(SET_ERROR_CODE, '')
                        commit(setShowNoData, false);
                        // resolve(true)
                    })
                    .catch((error) => {
                        commit(setShowNoData, true);
                        commit(GET_DEPOSIT_ACCOUNT_DETAILS_ERROR, error.response.data.message)
                        commit(SET_ERROR_CODE, error.response.status)
                        // reject(error)
                        })
            // })
        },
        UPDATE_DEPOSIT_PRODUCT({commit}, payload) {
            return new Promise((resolve, reject) => {
                ApiService.put(`DepositAdministration/Update/${payload.savingproductkey}`, payload)
                            .then( data  => {
                                commit(statusCreated, data)
                                resolve(true)
                            })
                            .catch( error => {
                                commit(depositErrors, error.message)
                                reject(error)
                            })
            })
        },
        DEACTIVATE_PRODUCT({commit}, payload) {
            return new Promise((resolve, reject) => {
                ApiService.put(`DepositAdministration/AlterProductStatus/${payload.savingproductkey}`, payload)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(error => {
                        commit(depositErrors, error.message)
                        reject(error)
                    })
            })
        },
        GET_TERM_UNIT({commit}) {
            ApiService.get(term_unit_uri)
                    .then( response => {
                        commit(setTermUnit, response.data)
                    })
                    .catch( error => {
                        commit(depositErrors, error.message)
                    })
        },
        [GET_DASHBOARD_STATS]({ commit }) {
            commit(LOADING_DASHBOARD_STATS, true);
            ApiService.get(`${baseUrl}dashboardIndicators/getuserindicators`)
              .then((res) => {
                commit(LOADING_DASHBOARD_STATS, false);
                commit(SET_DASHBOARD_STATS, res.data);
              })
              .catch(() => {
                commit(LOADING_DASHBOARD_STATS, false);
                commit(SET_DASHBOARD_STATS, {});
              });
          },
          [GET_ALL_INDICATORS]({ commit }) {
            commit(LOADING_ALL_INDICATORS, true);
            ApiService.get(`${baseUrl}dashboardIndicators/getallindicators`)
              .then((res) => {
                commit(LOADING_ALL_INDICATORS, false);
                commit(SET_ALL_INDICATORS, res.data);
              })
              .catch(() => {
                commit(LOADING_ALL_INDICATORS, false);
                commit(SET_ALL_INDICATORS, []);
              });
          },
          [GET_CUSTOMER_TYPES]({ commit }) {
            commit(LOADING_CUSTOMER_TYPES, true);
            GeneralApiService.post(`CustomerType/customertypes`,{
                clientType: 'Customer',
                pageIndex: 1,
                pageSize: 10000
            })
              .then((res) => {
                commit(LOADING_CUSTOMER_TYPES, false);
                commit(SET_CUSTOMER_TYPES, res.data);
              })
              .catch(() => {
                commit(LOADING_CUSTOMER_TYPES, false);
                commit(SET_CUSTOMER_TYPES, []);
              });
          },
        GET_GROUP_TYPES({ commit }) {
            commit(LOADING_CUSTOMER_TYPES, true);
            GeneralApiService.post(`CustomerType/customertypes`, {
                clientType: 'Group',
                pageIndex: 1,
                pageSize: 10000
            })
            .then((res) => {
                commit(LOADING_CUSTOMER_TYPES, false);
                commit(setGroupTypes, res.data);
            })
            .catch(() => {
                commit(LOADING_CUSTOMER_TYPES, false);
                commit(setGroupTypes, []);
            });
    },
  };
  
  export default {
    state,
    actions,
    mutations,
    getters
  };